.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sendButton {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin: auto;
  padding-top: 10px;
  max-width: 300px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin: auto;
  max-width: 300px;
}

Input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background: #ff9100;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background: #cc7503;
}

fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* divides the fieldset into 3 equal columns */
  gap: 10px; /* space between grid-items */
}

/* Optional: add some styles to the fieldset and its children */
fieldset {
  border: 1px solid #ddd;
  padding: 20px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 7px;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
  border-radius: 7px;
}

.react-datepicker-popper .react-datepicker__navigation {
  padding-top: 12px;
  color: #000;
}

.react-datepicker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
  border: none !important;
  font-family: "Inter" !important;
}

.react-datepicker__header {
  background: white !important;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 7px;
  background-color: white !important;
  width: 30px;
  height: 30px;
}

.react-datepicker__day.react-datepicker__day--selected {
  border: none;
  border-radius: 7px;
  background-color: black;
  color: white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border: none;
  border-radius: 7px !important;
}